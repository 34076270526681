import React, {FC, useState} from "react";
import {CategoryScoringMap, Company, QuestionSet} from "../api/dto";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {Select} from "../components/form/Select";
import {Button} from "../components/form/Button";
import {faSave, faUndo} from "@fortawesome/free-solid-svg-icons";

export const EditCompanyScoreOverridesModal: FC<{company: Company, questionSets: QuestionSet[]}> = props => {
  const {updateCompany} = useApiCall()
  const refresh = useRefresh()
  const {close} = useModalControls()
  const [scoringMap, setScoringMap] = useState<CategoryScoringMap|null>(props.company.scoringOverride)
  const [list, setList] = useState<string>('')
  const save = async () => {
    await updateCompany(props.company.id, props.company.name, scoringMap)
    refresh()
    close()
  }
  const clear = async () => {
    await updateCompany(props.company.id, props.company.name, null)
    refresh()
    close()
  }

  if (scoringMap === null) {
    return <div className={'flex flex-col items-center px-4 py-8'}>
      <h1>Aangepaste scoremarges instellen</h1>
      <p className={'text-sm mb-4 my-2'}>
        Je kunt op een bedrijf aangepaste scoremarges instellen voor alle examens binnen dat bedrijf. Het instellen van
        de marges gebeurt op basis van de standaardwaarden van een vragenlijst. Dit is omdat niet elke vragenlijst de
        zelfde marges en categorieën heeft.
      </p>
      <div className={'flex items-end space-x-3 justify-center'}>
        <Select label={'Vragenlijst'} options={{
          '': 'Selecteer een vragenlijst',
          ...props.questionSets.reduce((acc, qs) => ({...acc, [qs.id]: qs.name}), {})
        }} value={list} onChange={id => setList(id)} />
        <Button type={'primary'} size={'md'} text={'Instellen'} onClick={() => {
          const set = props.questionSets.find(qs => qs.id === list)
          if (set) {
            setScoringMap(set.scoringPerCategory)
          }
        }} />
      </div>
    </div>
  }
  return <div>
    {Object.entries(scoringMap).map(([category, scores], i) => {
      return <div key={i}>
        <h2 className={'font-medium my-2'}>{category}</h2>
        {scores.map((score, j) => {
          return <div key={j} className={'flex items-end mb-3'}>
            <div className={'h-10 flex items-center w-48'}>
              {score.classification}
            </div>
            <Input type={'number'} label={'Vanaf score'} value={score.fromScore} onChange={value => {
              setScoringMap({...scoringMap, [category]: scores.map((s, k) => k === j ? {...s, fromScore: Number(value)} : s)})
            }} />
          </div>
        })}
      </div>
    })}
    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'primary'}  size={'md'} text={"Opslaan"} icon={faSave} onClick={save}/>
      <Button type={'danger'}  size={'md'} text={"Herstellen"} icon={faUndo} onClick={clear}/>
      <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
    </footer>
  </div>
}
