import React, {FC, Fragment, useCallback, useMemo, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi, useFetchedResource} from "../api/APIContext";
import {Assessor, Company, Exam, ExamParticipant, Project, QuestionSet} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "../components/Loading";
import {useApiCall} from "../api/api";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {SectionHeader} from "../components/content/SectionHeader";
import {DataTable} from "../components/data/DataTable";
import {SectionDivider} from "../components/content/SectionDivider";
import {Card} from "../components/Card";
import {
  faArchive, faBackward,
  faCheckCircle, faChevronDown, faChevronUp, faCircleExclamation, faCircleNotch,
  faClock,
  faDownload,
  faPencil, faPerson,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "../components/form/Button";
import {IconDropdownButton} from "../components/form/IconButtonDropdown";
import {Select} from "../components/form/Select";

export const ExamDetailsPage: FC<{}> = () => {
  const {examId} = useParams()
  const {exams, projects, companies, questionSets, assessors, loading, refreshAll} = useApi()
  const {archiveExam, destroyExam, revertExam} = useApiCall()
  const navigate = useNavigate()
  const enrichedExam = useMemo(() => {
    const projectsMap = projects.reduce((map, project) => {
      map[project.id] = project
      return map
    }, {} as {[key: string]: Project})
    const companiesMap = companies.reduce((map, company) => {
      map[company.id] = company
      return map
    }, {} as {[key: string]: Company})
    const questionSetsMap = questionSets.reduce((map, questionSet) => {
      map[questionSet.id] = questionSet
      return map
    }, {} as {[key: string]: QuestionSet})
    const exam = exams.find(exam => exam.id === examId)
    if (!exam) return null
    const project = projectsMap[exam.projectId]
    const company = companiesMap[project?.companyId ?? '']
    const questionSet = questionSetsMap[exam.questionSetId]
    return {
      ...exam,
      projectName: project?.name ?? 'Onbekend project',
      project,
      companyName: company?.name ?? 'Onbekend bedrijf',
      company,
      questionSetName: questionSet?.name ?? 'Onbekende vragenlijst',
      questionSet,
    }

  }, [exams, projects, companies, questionSets, examId])

  const deleteExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen wilt archiveren?')) return
    await archiveExam(examId)
    refreshAll()
    navigate('/')
  }

  const permanentDeleteExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen permanent wilt verwijderen? \n Deze actie valt niet terug te draaien')) return
    await destroyExam(examId)
    refreshAll()
    navigate('/')
  }

  const revertArchivedExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen terug wilt zetten?')) return
    await revertExam(examId)
    refreshAll()
    navigate('/')
  }

  return <ContentContainer size={'md'}>
    <Loading loading={loading || !enrichedExam}>
      {enrichedExam && <div>
        <div className={"flex justify-between items-center"}>
          <PageHeader>Assessment {enrichedExam.name??''}</PageHeader>
          <div className={"h-12 mb-6 flex items-center"}>
            <ExamAssessor exam={enrichedExam} assessors={assessors} currentAssessor={enrichedExam.assessorId ? enrichedExam.assessorId : '-'} />
            {enrichedExam.archivedAt ? <div className={'flex'}>
              <Button type={'secondary'} size={'md'} text={'Examen terugzetten'} icon={faBackward} onClick={revertArchivedExam} />
                <Button type={'danger'} size={'md'} text={'Examen permanent Verwijderen'} icon={faCircleExclamation} onClick={permanentDeleteExam} />
            </div> :
            <Button type={'danger'} size={'md'} text={'Examen archiveren'} icon={faArchive} onClick={deleteExam} />}
          </div>
        </div>
        <dl className={"flex items-center"}>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Project
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.projectName}</dd>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Bedrijf
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.companyName}</dd>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Vragenlijst
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.questionSetName}</dd>
        </dl>
        <ExamResultsOverview exam={enrichedExam}/>
        <ExamParticipantsOverview exam={enrichedExam}/>
      </div>}
    </Loading>
  </ContentContainer>
}

const ExamAssessor: FC<{ exam: Exam, assessors: Assessor[], currentAssessor: string}> = props => {
  const {updateExam} = useApiCall();
  const reload = useRefresh()
  const refactoredAssessors = props.assessors.map((item) => [item.id, item.first_name + " " + item.last_name])
  const [selectedAssessor, setSelectedAssessor] = useState<string|null>(props.currentAssessor)

  const saveNewValues = useCallback(async (assessorId: string) => {
    const deployment = await updateExam(
      props.exam.id,
      {
        assessor_id: assessorId,
      })
    reload()
  }, []);

  return <div>
    <Select label={'Assessor'} inline value={selectedAssessor ?? '-'} options={Object.fromEntries(refactoredAssessors)} onChange={async (key) => {
      setSelectedAssessor(key)
      await saveNewValues(key)
    }} />
  </div>
}

const ExamResultsOverview: FC<{ exam: Exam }> = props => {
  const {getExamExportUrl, getExamReportUrl} = useApiCall()
  const [override, setOverride] = useState(false)
  const {listExamParticipants} = useApiCall()
  const participants = useFetchedResource<ExamParticipant[], Exam>((exam: Exam) => listExamParticipants(exam.id))
  useRefreshEffect(() => {
    participants.reload(props.exam)
  }, [props.exam])
  return <div>
    {/*<SectionDivider/>*/}
    {/*<SectionHeader>Resultaten</SectionHeader>*/}
    <Card title={'Resultaten'}>
      {(props.exam.status === 'Afgerond' || override) && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faCheckCircle} className={'text-brand-900'}/>
        <h2 className={"font-lg font-semibold text-brand-800"}>Finished</h2>
        <div className={"w-full"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <div className={""}>
              <h3 className={"font-bold"}>Overall Test Results / Competency Test [.PDF]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen en scorekaarten van alle aanwezige contractors in één
                .pdf file </p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download .pdf [Overall Test Results]'}
                      onClick={() => {
                        window.open(getExamReportUrl(props.exam.id), '_blank')
                        // window.open(getExamExportUrl(props.exam.id, true), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Test Results / Competency Test per Contractor [.PDF]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen en scorekaarten van alle aanwezige contractors apart
                in een .pdf file</p>
            </div>
            <div className={"flex flex-col items-start"}>
              {Array.from(new Set(participants.resource?.map(p => p.contractor) ?? [])).map((contractor, i) => {
                return <Fragment key={i}>
                  <h2 className={'font-medium'}>{contractor}</h2>
                  <Button size={'md'} type={'secondary'} icon={faDownload}
                          text={`Download .pdf [Test Results per Contractor]`}
                          onClick={() => {
                            window.open(getExamReportUrl(props.exam.id, contractor), '_blank')
                          }}/>
                </Fragment>
              })}
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Overall Test Results / Competency Test [.XSLX]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen van alle contractors in één .xlsx file</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download .xlsx [Overall Test Results]'}
                      onClick={() => {
                        window.open(getExamExportUrl(props.exam.id, false), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Export Overall Data Scorecard [.XSLX]</h3>
              <p className={"text-slate-800"}>Volledig export van alle ingevulde scorecards in één .xlsx file.</p>
            </div>
            <div className={"flex flex-col items-start"}>
              <Button size={'md'} type={'secondary'} icon={faDownload}
                      text={`Download .xlsx [Export Overall Data Scorecard]`}
                      onClick={() => {
                        window.open(getExamExportUrl(props.exam.id, true), '_blank')
                      }}/>
            </div>

          </div>
        </div>
      </div>}
      {props.exam.status === 'In uitvoering' && !override &&
        <div className={'flex flex-col items-center space-y-3 py-4'}>
          <FontAwesomeIcon icon={faClock} className={'text-brand-900'}/>
          <h2 className={"font-lg font-semibold text-brand-800"}>In progress</h2>
          <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
            exporteren</p>
          <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren
          </button>
        </div>}
      {props.exam.status === 'Concept' && !override && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faPencil} className={'text-brand-900'}/>
        <h2 className={"font-lg font-semibold text-brand-800"}>Concept</h2>
        <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
          exporteren</p>
        <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren
        </button>
      </div>}
    </Card>
  </div>

}

const ExamParticipantsOverview: FC<{ exam: Exam }> = props => {
  const {listExamParticipants} = useApiCall()
  const participants = useFetchedResource<ExamParticipant[], Exam>((exam: Exam) => listExamParticipants(exam.id))
  useRefreshEffect(() => {
    participants.reload(props.exam)
  }, [props.exam])
  return <div>
    <SectionDivider/>
    <SectionHeader>Deelnemers</SectionHeader>
    <Loading loading={participants.loading}>
      <DataTable<ExamParticipant> keyProperty={'id'} data={(participants.resource ?? []) as ExamParticipant[]}
                                  columns={[
                                    {header: 'Position', property: 'position'},
                                    {
                                      header: 'Naam',
                                      property: 'name',
                                      transform: (name, participant) => participant.noShow ?
                                        <span className={'text-red-700 font-bold'}>{name} (NO-SHOW)</span> :
                                        <span>{name}</span>
                                    },
                                    {header: 'Contractor', property: 'contractor'},
                                    {header: 'Subcontractor', property: 'subContractor'},
                                    {header: 'Badge', property: 'badgeNumber'},
                                    {header: 'Certification', property: 'certification'},
                                  ]} placeholder={<>Nog geen deelnemers</>}/>
    </Loading>
  </div>
}
